import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

export const dateFormat = (timeStamp) => {
  dayjs.extend(LocalizedFormat);
  if (timeStamp) {
    const date = dayjs(timeStamp);
    if (dayjs().isSame(date, "date")) {
      return date.format("LT");
    }
    return date.format("LT - LL");
  }
};

export const sortBy = (prop) => {
  return function(a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};
