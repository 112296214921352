import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { ApnaTimeLogo } from "../../utils/Svg";
import UserSearch from "./UserSearch";
import UserChat from "./UserChat";
import UsersListUnread from "./UsersListUnread";
import Spinner from "../dashboard/Spinner";
import "./UsersList.css";
import { loadMoreUsersList } from "../../actions/update-users-list.action";

class UsersList extends Component {
  handhandleChange = (e) => {
    e.preventDefault();
  };

  renderList = () => {
    const { usersList } = this.props;
    const users = usersList?.users;
    if (usersList?.loadingUsers) {
      return <Spinner />;
    }
    if (!usersList?.loadingUsers && users && users.length > 0) {
      return users.map((user) => (
        <UserChat key={user?.userDetails?.userId} chat={user} />
      ));
    }
  };
  loadMore = () => {
    const { usersList } = this.props;
    const lastCreatedAtTime = usersList?.lastCreatedAtTime;
    this.props.loadMoreUsersList(lastCreatedAtTime);
  };
  render() {
    const { usersList, activeUser } = this.props;
    const users = usersList?.users;
    return (
      <div className="sidebar">
        <div className="sidebar__header">
          <ApnaTimeLogo />
          <Avatar alt={activeUser?.full_name} src={activeUser?.profileUrl} />
        </div>
        <UserSearch />
        {!usersList?.showUnreadList && (
          <div className="sidebar__chat">{this.renderList()}</div>
        )}
        {usersList?.showUnreadList && <UsersListUnread />}
        {users && users.length >= 50 && (
          <div className="sidebar__footer" onClick={() => this.loadMore()}>
            {usersList?.loadingMoreUsers && <Spinner />}
            <Button variant="contained">show more conversations</Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
  usersList: state.usersList,
});

export default connect(mapStateToProps, { loadMoreUsersList })(UsersList);
