import {
  INITIALIZE_APP,
  MESSAGE_LOADING_STARTED,
  UPDATE_MESSAGES_LIST,
  LOAD_MORE_MESSAGE_LIST,
  UPDATE_LAST_MESSAGE_TIME,
  USER_TYPING,
  UPDATE_SELECTED_CHAT_USER_DETAILS,
  NEW_MESSAGE_SENT,
  UPDATE_FAQ_LIST,
  UPDATE_JOBS_TITLES_LIST,
  MESSAGES_LIST_HAS_MORE,
  GOOGLE_SHEET_ENTRY,
  DELETE_MESSAGE,
  SET_REPLY_TO_TAG_MESSAGE,
  FETCH_SELECTED_CHAT_USER_DETAILS,
} from "../constants/types";

const initialState = {
  userFAQList: JSON.parse(localStorage.getItem("userFAQList")),
  candidateJobsList: JSON.parse(localStorage.getItem("candidateJobsList")),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_LOADING_STARTED:
      return {
        ...state,
        loadingMessages: true,
      };
    case UPDATE_SELECTED_CHAT_USER_DETAILS:
      return {
        ...state,
        selectedChatUserDetails: action.payload,
      };
    case UPDATE_MESSAGES_LIST:
      return {
        ...state,
        messageList: action.messages,
        hasMore: action.hasMore,
        lastCreatedTime: action.lastCreatedTime,
        loadingMessages: false,
        fetchedMore: false,
      };
    case LOAD_MORE_MESSAGE_LIST:
      return {
        ...state,
        messageList: [...action.messages, ...state.messageList],
        hasMore: action.hasMore,
        scrollTo: action.scrollTo,
        lastCreatedTime: action.lastCreatedTime,
        loadingMessages: false,
        fetchedMore: true,
      };
    case MESSAGES_LIST_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };
    case UPDATE_LAST_MESSAGE_TIME:
      return {
        ...state,
        lastCreatedTime: action.payload,
      };
    case USER_TYPING:
      return {
        ...state,
        typingStatusOfOtherUser: action.payload,
      };
    case NEW_MESSAGE_SENT:
      return {
        ...state,
        sentMessage: action.payload,
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        deleteMessage: action.payload,
      };
    case SET_REPLY_TO_TAG_MESSAGE:
      return {
        ...state,
        replyToTagMessage: action.payload,
      };
    case UPDATE_FAQ_LIST:
      return {
        ...state,
        userFAQList: action.payload,
      };
    case UPDATE_JOBS_TITLES_LIST:
      return {
        ...state,
        candidateJobsList: action.payload,
      };
    case FETCH_SELECTED_CHAT_USER_DETAILS:
      return {
        ...state,
        selectedChatUserInfo: action.payload,
      };
    case GOOGLE_SHEET_ENTRY:
      return {
        ...state,
        googleSheetEntry: action.payload,
      };
    default:
      return state;
  }
};
