import { OPEN_FAQ_PANEL, CLOSE_FAQ_PANEL } from "../constants/types";

const initialState = { faqOpen: false };

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_FAQ_PANEL:
      return {
        ...state,
        faqOpen: true,
      };
    case CLOSE_FAQ_PANEL:
      return {
        ...state,
        faqOpen: false,
      };
    default:
      return state;
  }
};
