import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dateFormat } from "../../utils/formatDate";
import { Avatar } from "@material-ui/core";
import { setSelectedChat } from "../../actions/update-message-list.action";
import { resetSelectedChatCount } from "../../actions/update-new-message-count.action";
import UserMessageCount from "./UserMessageCount";

function UserChat({ chat }) {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const newMessageCount = useSelector((state) => state.newMessageCount);
  const user = chat?.userDetails;
  const selectedId =
    messages.selectedChatUserDetails &&
    Array.isArray(messages.selectedChatUserDetails) &&
    messages.selectedChatUserDetails.length > 0 &&
    messages.selectedChatUserDetails[0]?.userId;

  const fetchChatForSelectedUser = () => {
    dispatch(resetSelectedChatCount(chat?.userDetails?.userId));
    dispatch(setSelectedChat(chat?.userDetails?.userId));
  };

  return (
    <div
      className={`sidebarChat ${user?.userId === selectedId &&
        "sidebarChat__select"}`}
      onClick={fetchChatForSelectedUser}
    >
      <Avatar alt={user?.userName} src={user?.imageLink} />
      <div className="sidebarChat__info">
        <h2>{`${user?.userName} - ${user?.userId}`}</h2>
        <p>
          {dateFormat(newMessageCount && newMessageCount[user?.userId]?.time)}
        </p>
      </div>
      <div className="sidebarChat__infoRight">
        <UserMessageCount id={user?.userId} />
      </div>
    </div>
  );
}

export default UserChat;
