import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SearchOutlined } from "@material-ui/icons";
import { setSelectedChat } from "../../actions/update-message-list.action";

class UserSearch extends PureComponent {
  static propTypes = {
    onSearchById: PropTypes.func.isRequired,
  };
  state = {
    searchId: "",
  };
  handleChange = (e) => {
    this.setSearchTerm(e.target.value);
  };
  setSearchTerm = (term) => {
    this.setState({ searchId: term });
  };
  handleSearch = (e) => {
    if (e.key === "Enter") {
      this.searchUserById(e);
    }
  };
  searchUserById = (e) => {
    e.preventDefault();
    if (this.state.searchId !== "" && this.state.searchId.trim() !== "") {
      this.props.onSearchById(this.state.searchId);
    }
    this.setSearchTerm("");
  };
  render() {
    return (
      <div className="sidebar__search">
        <SearchOutlined />
        <input
          placeholder="Search By User Id..."
          value={this.state.searchId}
          onChange={this.handleChange}
          onKeyPress={this.handleSearch}
        />
      </div>
    );
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    onSearchById: (id) => dispatch(setSelectedChat(id)),
  })
)(UserSearch);
