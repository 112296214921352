import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  AddDataToLocalStorage,
  GetCurrentStorage,
} from "../../utils/LocalStorage";
import UsersList from "../users/UsersList";
import Messages from "../messages/Messages";
import { element } from "prop-types";
import Mixpanel from "../../utils/Mixpanel";
import "./MainLayout.css";
import {
  updateUsersList,
  setLastCreatedAtTime,
} from "../../actions/update-users-list.action";
import { updateMessageList } from "../../actions/update-message-list.action";
import { typingStatusOfOtherUser } from "../../actions/update-message-list.action";
import { initLoadingUser } from "../../actions/update-users-list.action";
import {
  initMessageCount,
  updateOneMessageCount,
  updateSelectUserTimestamp,
} from "../../actions/update-new-message-count.action";
import { loadUser } from "../../actions/auth.action";
import {
  APPLOZIC_BASE_URL,
  APPLOZIC_KEY,
  APPLOZIC_PASSWORD,
  APPLOZIC_WEBSOCKET_URL,
} from "../../utils/config";

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneToOneMessage: true,
    };
  }
  componentDidMount() {
    this.userOnAppLozic();
  }
  onMessageRecieved = (response) => {
    if (response?.message?.from) {
      this.props.updateOneMessageCount(response?.message?.from);
    }
  };

  allChatList = () => {
    let userId = this.props.auth?.user?.id || this.props.activeUser?.user?.id;
    let password;
    this.props.initLoadingUser();

    const that = this;
    window.Applozic.ALApiService.getMessages({
      data: { startIndex: 0, mainPageSize: 50 },
      success: function(response) {
        let newList = [];
        let newUserDetails = [];
        if (response && response.data && response.data.message) {
          let lastCreatedAtTime = response.data.message[
            response.data.message.length - 1
          ]
            ? response.data.message[response.data.message.length - 1]
                .createdAtTime
            : "";
          that.props.setLastCreatedAtTime(lastCreatedAtTime);
          response.data.message.map((element) => {
            if (element.to && !element.groupId) {
              newList.push(element);
            }
          });
          let responseUserDetails =
            response &&
            response.data &&
            response.data.message &&
            response.data.userDetails;

          newList.forEach((element) => {
            newUserDetails.push({
              userDetails: _.find(responseUserDetails, {
                userId: element.to,
              }),
              messageDetails: element,
            });
          });

          that.props.updateUsersList(newUserDetails);
          that.props.initMessageCount(newUserDetails);
          that.setState({ oneToOneMessage: false });
        }
      },
      error: function() {},
    });
    // window.Applozic.ALSocket.subscibeToTypingChannel(userId);
  };

  onMessageSent = () => {
    const selectedChatUser =
      this.props.messages && this.props.messages.selectedChatUserDetails;

    const that = this;
    window.Applozic.ALApiService.getMessages({
      data: {
        startIndex: 0,
        userId: selectedChatUser && selectedChatUser[0].userId,
        pageSize: 50,
      },
      success: function(response) {
        that.props.updateMessageList(response);
      },
      error: function() {
        console.log("send failed");
      },
    });
  };

  userOnAppLozic = () => {
    let userId = this.props.auth?.user?.id || this.props.activeUser?.user?.id;
    let password = true;

    let selectedUserChat = GetCurrentStorage("selectedUserChat");
    const that = this;
    let events = {
      onConnectFailed: function(resp) {
        window.Applozic.ALSocket.reconnect();
      },
      onConnect: function(resp) {
        setTimeout(that.allChatList, 0);
      },
      onMessageDelivered: function(resp) {},
      onMessageRead: function(resp) {
        //called when a message is read by the receiver
      },
      onMessageDeleted: function(resp) {},
      onConversationDeleted: function(resp) {},
      onUserConnect: function(resp) {},
      onUserDisconnect: function(resp) {},
      onConversationReadFromOtherSource: function(resp) {},
      onConversationRead: function(resp) {
        //called when the conversation thread is read by the receiver
      },
      onMessageReceived: function(resp) {
        // that.newMessageCount(resp);
        that.onMessageRecieved(resp);
        // that.props.typingStatusOfOtherUser();
      },
      onMessageSentUpdate: function(resp) {},
      onMessageSent: function(resp) {},
      onUserBlocked: function(resp) {},
      onUserUnblocked: function(resp) {},
      onUserActivated: function(resp) {},
      onUserDeactivated: function(resp) {},
      connectToSocket: function(resp) {},
      onMessage: function(resp) {
        //called when the websocket receive the data
      },
      onTypingStatus: function(resp) {
        that.props.typingStatusOfOtherUser(resp);
      },
    };
    window.Applozic.ALApiService.login({
      data: {
        baseUrl: APPLOZIC_BASE_URL,
        alUser: {
          userId: userId, //Logged in user's id, a unique identifier for user
          password: password ? APPLOZIC_PASSWORD : null, //Enter password here for the userId passed above, read this if you want to add additional security by verifying password from your server https://www.applozic.com/docs/configuration.html#access-token-url
          imageLink: "", //User's profile picture url
          email: "", //optional
          contactNumber: "", //optional, pass with internationl code eg: +13109097458
          appVersionCode: 108,
          applicationId: `${process.env.REACT_APP_APPLOZIC_KEY}`, //Get your App ID from [Applozic Dashboard](https://console.applozic.com/settings/install)
        },
      },
      success: function(response) {
        Mixpanel.identify(response.userId);
        let data = {};
        data.token = response.token;
        data.deviceKey = response.deviceKey;
        data.websocketUrl = APPLOZIC_WEBSOCKET_URL;
        //Get your App ID from [Applozic Dashboard](https://console.applozic.com/settings/install)
        window.Applozic.ALSocket.init(APPLOZIC_KEY, data, events);

        // This method initializes socket connection
      },
      error: function() {},
    });
  };

  render() {
    return (
      <div className="app">
        <UsersList />
        <Messages />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initialized: state.init.initialized,
  activeUser: state.activeUser,
  auth: state.auth,
  usersList: state.users,
  messages: state.messages,
});

export default connect(mapStateToProps, {
  loadUser,
  typingStatusOfOtherUser,
  initLoadingUser,
  updateUsersList,
  updateMessageList,
  initMessageCount,
  updateOneMessageCount,
  setLastCreatedAtTime,
  updateSelectUserTimestamp,
})(MainLayout);
