import { FETCH_JOBS_LOADING, FETCH_JOBS_FOR_TITLE } from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_JOBS_LOADING:
      return {
        ...state,
        loadingJobs: payload,
      };
    case FETCH_JOBS_FOR_TITLE:
      return {
        ...state,
        jobsData: payload,
        loadingJobs: false,
      };
    default:
      return state;
  }
};
