import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dateFormat } from "../../utils/formatDate";
import {
  reloadUsersList,
  toggleUnreadList,
} from "../../actions/update-users-list.action";
import { logout } from "../../actions/auth.action";
import RightSidebar from "./RightSidebar";

export default function MessageHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const users = useSelector((state) => state.usersList);
  const showUnread = users.showUnreadList;
  const selectedChatUserDetails = messages?.selectedChatUserDetails;
  const totalCount = useSelector((state) => state.newMessageCount?.totalCount);
  const resetCount = () => {
    dispatch(reloadUsersList());
  };
  const toggleUnread = () => {
    dispatch(toggleUnreadList());
  };
  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear();
    window.location.reload();
  };
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const onCloseUserInfo = () => setOpenUserInfo(false);

  return (
    <div className="message__header">
      {selectedChatUserDetails && selectedChatUserDetails.length > 0 && (
        <Avatar
          alt={
            selectedChatUserDetails &&
            selectedChatUserDetails[0] &&
            selectedChatUserDetails[0].userName
          }
          src={
            selectedChatUserDetails &&
            selectedChatUserDetails[0] &&
            selectedChatUserDetails[0].imageLink
          }
          onClick={() => setOpenUserInfo(true)}
        />
      )}
      <div className="message__headerInfo">
        {selectedChatUserDetails && selectedChatUserDetails.length > 0 && (
          <>
            <h3>{`${selectedChatUserDetails[0].userName}  (
            ${selectedChatUserDetails[0].userId} - 
            ${selectedChatUserDetails[0].metadata &&
              selectedChatUserDetails[0].metadata.city}
            )`}</h3>
            <p>
              {`Last seen ${dateFormat(
                selectedChatUserDetails[0].lastSeenAtTime
              )}`}
            </p>
          </>
        )}
      </div>

      <div className="message__headerRight">
        {totalCount > 0 && (
          <Button
            variant="contained"
            classes={{
              root: classes.count,
              label: classes.countLabel,
            }}
            onClick={() => resetCount()}
          >
            {totalCount}
          </Button>
        )}
        <Button
          variant="contained"
          classes={{
            root: classes.count,
            label: classes.label,
          }}
          onClick={() => toggleUnread()}
        >
          {showUnread ? "Show All" : "Show Unread"}
        </Button>
        <Button
          variant="contained"
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          onClick={() => handleLogout()}
        >
          Logout
        </Button>
      </div>
      <RightSidebar isOpen={openUserInfo} onClose={onCloseUserInfo} />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    background: "#e0e0e0",
    borderRadius: 3,
    border: 0,
    color: "black",
    height: 22,
    padding: "15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
  },
  label: {
    textTransform: "capitalize",
    fontSize: "smaller",
  },
  count: {
    background: "#2bb793",
    borderRadius: 4,
    border: 0,
    color: "white",
    height: 22,
    padding: "15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
  },
  countLabel: {
    fontWeight: 700,
    fontSize: "smaller",
  },
});
