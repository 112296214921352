import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

function UserMessageCount({ id }) {
  const count = useSelector(
    (state) => state.newMessageCount && state.newMessageCount[id]?.count
  );
  return <StyledBadge color="secondary" badgeContent={count} />;
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#2bb793",
  },
}))(Badge);

export default UserMessageCount;
