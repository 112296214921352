import {
  INIT_MESSAGE_COUNT,
  INIT_TOTAL_MESSAGE_COUNT,
  UPDATE_ONE_MESSAGE_COUNT,
  INCREASE_TOTAL_MESSAGE_COUNT,
  RESET_TOTAL_MESSAGE_COUNT,
  RESET_SELECTED_MESSAGE_COUNT,
  UPDATE_SELECTED_USER_TIMESTAMP,
} from "../constants/types";

export const initMessageCount = (usersList, loadMore) => (
  dispatch,
  getState
) => {
  let messageCountList = {};

  usersList.forEach((user) => {
    messageCountList[user?.userDetails?.userId] = {
      count: parseInt(user?.userDetails?.unreadCount) || 0,
      time: user?.messageDetails?.createdAtTime,
    };
  });
  let { newMessageCount } = getState();
  let total = loadMore ? newMessageCount.totalCount : 0;
  for (const key in messageCountList) {
    if (key !== "totalCount") {
      total = total + messageCountList[key]?.count;
    }
  }

  dispatch({
    type: INIT_MESSAGE_COUNT,
    payload: messageCountList,
  });
  dispatch({
    type: INIT_TOTAL_MESSAGE_COUNT,
    payload: total,
  });
};

export const updateOneMessageCount = (id) => (dispatch) => {
  dispatch(increaseTotalMessageCount());
};

export const increaseTotalMessageCount = () => (dispatch) => {
  dispatch({
    type: INCREASE_TOTAL_MESSAGE_COUNT,
    payload: true,
  });
};

export const resetTotalMessageCount = () => (dispatch) => {
  dispatch({
    type: RESET_TOTAL_MESSAGE_COUNT,
    payload: true,
  });
};

export const resetSelectedChatCount = (id) => (dispatch) => {
  dispatch({
    type: RESET_SELECTED_MESSAGE_COUNT,
    payload: id,
  });
};

export const updateSelectUserTimestamp = (id) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_USER_TIMESTAMP,
    payload: id,
  });
};
