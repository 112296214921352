import { Get, Post, GoogleGet } from "../utils/api";
import {
  AddDataToLocalStorage,
  GetCurrentStorage,
} from "../utils/LocalStorage";
import APIConfig from "../utils/APIConfig";
import { sortBy } from "../utils/formatDate";

import {
  LOADING_MESSAGES_LIST,
  UPDATE_MESSAGES_LIST,
  UPDATE_LAST_MESSAGE_TIME,
  LOAD_MORE_MESSAGE_LIST,
  USER_TYPING,
  UPDATE_SELECTED_CHAT_USER_DETAILS,
  FETCH_SELECTED_CHAT_USER_DETAILS,
  MESSAGES_LIST_HAS_MORE,
  MESSAGE_LOADING_STARTED,
  GOOGLE_SHEET_ENTRY,
  DELETE_MESSAGE,
  SET_REPLY_TO_TAG_MESSAGE,
} from "../constants/types";

export const setSelectedChat = (id) => (dispatch) => {
  let selectedChatUserId = id;
  dispatch({
    type: MESSAGE_LOADING_STARTED,
    loadingMessages: true,
  });
  window.Applozic.ALApiService.conversationReadUpdate({
    data: `userId=${encodeURIComponent(selectedChatUserId)}`,
    success: function(result) {
      window.Applozic.ALApiService.getMessages({
        data: {
          startIndex: 0,
          userId: selectedChatUserId,
          pageSize: 50,
        },
        success: function(response) {
          dispatch(updateMessageList(response));
          dispatch(updateSelectChatUserDetails(response));
          dispatch(fetchMoreSelectChatUserDetails(response));
        },
        error: function() {},
      });
    },
    error: function() {},
  });
};

export const updateMessageList = (reponse, loadMore, scrollTo) => (
  dispatch
) => {
  // if (reponse.data && reponse.data.message && reponse.data.message.length > 0) {

  if (reponse.data && reponse.data.message) {
    let messages =
      reponse.data && reponse.data.message.sort(sortBy("createdAtTime"));
    let hasMore = messages.length === 50 ? true : false;
    if (loadMore) {
      dispatch({
        type: LOAD_MORE_MESSAGE_LIST,
        messages: messages,
        scrollTo: scrollTo,
        hasMore: hasMore,
        lastCreatedTime: messages && messages[0] && messages[0].createdAtTime,
      });
    } else {
      dispatch({
        type: UPDATE_MESSAGES_LIST,
        messages: messages,
        hasMore: hasMore,
        lastCreatedTime: messages && messages[0] && messages[0].createdAtTime,
      });
    }
  } else {
    console.log("end of list");
  }
};

export const loadMoreMessageList = (userId, lastMessageTime, scrollTo) => (
  dispatch
) => {
  window.Applozic.ALApiService.getMessages({
    data: {
      startIndex: 0,
      userId: userId,
      endTime: lastMessageTime,
      pageSize: 50,
    },
    success: function(response) {
      dispatch(updateMessageList(response, true, scrollTo));
    },
    error: function() {
      console.log("error");
    },
  });
};

export const updateSelectChatUserDetails = (reponse) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_CHAT_USER_DETAILS,
    payload: reponse && reponse.data && reponse.data.userDetails,
  });
};

export const fetchMoreSelectChatUserDetails = (reponse) => (dispatch) => {
  let userDetails = reponse && reponse.data && reponse.data.userDetails;
  let userId = userDetails && userDetails[0] && userDetails[0].userId;

  Get(`${APIConfig.SELECTED_USER_INFO}?user_id=${userId}`)
    .then((res) => {
      dispatch({
        type: FETCH_SELECTED_CHAT_USER_DETAILS,
        payload: res,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteMsg = (messageKey) => (dispatch) => {
  window.Applozic.ALApiService.deleteMessage({
    data: { key: messageKey },
    success: function(response) {
      dispatch({
        type: DELETE_MESSAGE,
        payload: messageKey,
      });
    },
    error: function(error) {
      console.log(error);
    },
  });
};

export const setReplyToTagMessage = (message) => (dispatch) => {
  dispatch({
    type: SET_REPLY_TO_TAG_MESSAGE,
    payload: message,
  });
};

export const typingStatusOfOtherUser = (data) => (dispatch) => {
  if (data !== undefined) {
    dispatch({
      type: USER_TYPING,
      payload: false,
    });
  } else {
    dispatch({
      type: USER_TYPING,
      payload: false,
    });
  }
};
