import {
  INIT_MESSAGE_COUNT,
  INIT_TOTAL_MESSAGE_COUNT,
  UPDATE_ONE_MESSAGE_COUNT,
  INCREASE_TOTAL_MESSAGE_COUNT,
  RESET_TOTAL_MESSAGE_COUNT,
  RESET_SELECTED_MESSAGE_COUNT,
  UPDATE_SELECTED_USER_TIMESTAMP,
} from "../constants/types";

const initialState = {
  totalCount: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_MESSAGE_COUNT:
      return {
        ...state,
        ...payload,
      };
    case INIT_TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalCount: parseInt(payload),
      };
    case UPDATE_ONE_MESSAGE_COUNT:
      return {
        ...state,
        [parseInt(payload)]: {
          count: state[parseInt(payload)].count + 1,
          time: new Date(),
        },
      };
    case RESET_SELECTED_MESSAGE_COUNT:
      return {
        ...state,
        totalCount:
          state.totalCount - state[parseInt(payload)]?.count > 0
            ? state.totalCount - state[parseInt(payload)]?.count
            : 0,
        [parseInt(payload)]: { count: 0, time: state[parseInt(payload)].time },
      };
    case INCREASE_TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalCount: state.totalCount + 1 || 1,
      };
    case RESET_TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalCount: 0,
      };
    case UPDATE_SELECTED_USER_TIMESTAMP:
      return {
        ...state,
        [parseInt(payload)]: { count: 0, time: new Date() },
      };
    default:
      return state;
  }
};
