import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    right: `${left}%`,
    outline: "none",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  };
}
function getTabsStyle() {
  return {
    backgroundColor: "#4f3951",
    color: "white",
  };
}

function getTabStyle() {
  return {
    outline: "none",
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: "absolute",
    width: 300,
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SideTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tabsStyle] = useState(getTabsStyle);
  const [eachTabStyle] = useState(getTabStyle);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          style={tabsStyle}
          value={value}
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab style={eachTabStyle} label="User" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <UserInfo />
      </TabPanel>
    </div>
  );
}

function UserInfo() {
  const messages = useSelector((state) => state.messages);
  const userInfo = messages && messages.selectedChatUserInfo;
  return (
    <div className="userInfo">
      <p className="userInfo_row">
        Name: <span> {(userInfo && userInfo.full_name) || null}</span>
      </p>
      <p className="userInfo_row">
        Phone: <span>{(userInfo && userInfo.phone_number) || null} </span>
      </p>
      <p className="userInfo_row">
        Area:{" "}
        <span>
          {(userInfo && userInfo.profile && userInfo.profile.area) || null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        City:{" "}
        <span>
          {(userInfo && userInfo.profile && userInfo.profile.city) || null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Education:{" "}
        <span>
          {(userInfo &&
            userInfo.work_info &&
            userInfo.work_info.education_level) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Experience:{" "}
        <span>
          {(userInfo &&
            userInfo.work_info &&
            userInfo.work_info.experience_level) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Exp In Years:{" "}
        <span>
          {(userInfo &&
            userInfo.work_info &&
            userInfo.work_info.experience_in_years) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Current Salary:{" "}
        <span>
          {(userInfo &&
            userInfo.work_info &&
            userInfo.work_info.current_salary) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Current Company:{" "}
        <span>
          {(userInfo && userInfo.work_info && userInfo.work_info.company) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Current Job Title:{" "}
        <span>
          {(userInfo &&
            userInfo.work_info &&
            userInfo.work_info.current_job_title) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        Interests:{" "}
        <span>
          {(userInfo && userInfo.work_info && userInfo.work_info.interests) ||
            null}{" "}
        </span>
      </p>
      <p className="userInfo_row">
        App Version:{" "}
        <span>
          {(userInfo &&
            userInfo.userdeviceinfo &&
            userInfo.userdeviceinfo.app_version) ||
            null}{" "}
        </span>
      </p>
    </div>
  );
}

const RightSidebar = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <div>
      <Modal open={isOpen} onClose={onClose}>
        <div style={modalStyle} className={classes.paper}>
          <SideTabs />
        </div>
      </Modal>
    </div>
  );
};

export default RightSidebar;
