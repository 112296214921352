import { Get, OpenPost } from "../utils/api";
import { AddDataToLocalStorage } from "../utils/LocalStorage";
import { initMessageCount } from "./update-new-message-count.action";
import {
  USER_LOADING_STARTED,
  USER_LOADING_MORE_STARTED,
  UPDATE_USERS_LIST,
  SET_LAST_CREATED_AT_TIME_USERS,
  LOAD_MORE_USERS_LIST,
  TOGGLE_SHOW_UNREAD_LIST,
} from "../constants/types";
import _ from "lodash";

export const initLoadingUser = () => (dispatch) => {
  dispatch({
    type: USER_LOADING_STARTED,
    payload: true,
  });
};

export const updateUsersList = (usersList) => (dispatch) => {
  dispatch({
    type: UPDATE_USERS_LIST,
    payload: usersList,
  });
};

export const addMoreUsersList = (usersList) => (dispatch) => {
  dispatch({
    type: LOAD_MORE_USERS_LIST,
    payload: usersList,
  });
};

export const setLastCreatedAtTime = (lastCreatedAtTime) => (dispatch) => {
  dispatch({
    type: SET_LAST_CREATED_AT_TIME_USERS,
    payload: lastCreatedAtTime,
  });
};

export const loadMoreUsersList = (lastCreatedAtTime) => (dispatch) => {
  dispatch({
    type: USER_LOADING_MORE_STARTED,
    payload: true,
  });
  window.Applozic.ALApiService.getMessages({
    data: {
      startIndex: 0,
      endTime: lastCreatedAtTime ? lastCreatedAtTime : "",
      mainPageSize: 50,
    },
    success: function(response) {
      let newList = [];
      let newUserDetails = [];
      if (response && response.data && response.data.message) {
        let createdAtTime = response.data.message[
          response.data.message.length - 1
        ]
          ? response.data.message[response.data.message.length - 1]
              .createdAtTime
          : "";
        response.data.message.map((element) => {
          if (element.to && !element.groupId) {
            newList.push(element);
          }
        });
        let responseUserDetails =
          response &&
          response.data &&
          response.data.message &&
          response.data.userDetails;

        newList.forEach((element) => {
          newUserDetails.push({
            userDetails: _.find(responseUserDetails, {
              userId: element.to,
            }),
            messageDetails: element,
          });
        });
        dispatch(setLastCreatedAtTime(createdAtTime));
        dispatch(addMoreUsersList(newUserDetails));
        dispatch(initMessageCount(newUserDetails, true));
      }
    },
    error: function() {},
  });
};

export const reloadUsersList = () => (dispatch) => {
  window.Applozic.ALApiService.getMessages({
    data: { startIndex: 0, mainPageSize: 50 },
    success: function(response) {
      let newList = [];
      let newUserDetails = [];
      if (response && response.data && response.data.message) {
        let lastCreatedAtTime = response.data.message[
          response.data.message.length - 1
        ]
          ? response.data.message[response.data.message.length - 1]
              .createdAtTime
          : "";
        setLastCreatedAtTime(lastCreatedAtTime);
        response.data.message.map((element) => {
          if (element.to && !element.groupId) {
            newList.push(element);
          }
        });
        let responseUserDetails =
          response &&
          response.data &&
          response.data.message &&
          response.data.userDetails;

        newList.forEach((element) => {
          newUserDetails.push({
            userDetails: _.find(responseUserDetails, {
              userId: element.to,
            }),
            messageDetails: element,
          });
        });

        dispatch(updateUsersList(newUserDetails));
        dispatch(initMessageCount(newUserDetails));
      }
    },
    error: function() {},
  });
};

export const toggleUnreadList = () => (dispatch) => {
  dispatch({
    type: TOGGLE_SHOW_UNREAD_LIST,
    payload: true,
  });
};
