import {
  NEW_MESSAGE_SENT,
  UPDATE_JOBS_TITLES_LIST,
  FETCH_JOBS_LOADING,
  FETCH_JOBS_FOR_TITLE,
  UPDATE_FAQ_LIST,
  OPEN_FAQ_PANEL,
  CLOSE_FAQ_PANEL,
} from "../constants/types";
import { updateMessageList } from "./update-message-list.action";
import {
  AddDataToLocalStorage,
  GetCurrentStorage,
} from "../utils/LocalStorage";
import APIConfig from "../utils/APIConfig";
import { jobCategoryQuery, jobCategory, Get, Post } from "../utils/api";
import Tabletop from "tabletop";
import Mixpanel from "../utils/Mixpanel";

export const sendNewMessage = (newMessage, template) => (
  dispatch,
  getState
) => {
  let newText = template ? newMessage : newMessage.text.trim();
  let senderName = GetCurrentStorage("senderName");
  let { messages } = getState();
  let selectedChatUserName =
    messages.selectedChatUserDetails &&
    messages.selectedChatUserDetails[0].userName;
  let selectedChatUserId =
    messages.selectedChatUserDetails &&
    messages.selectedChatUserDetails[0].userId;
  let message = {
    type: 5,
    contentType: 0,
    message: newText,
    to: selectedChatUserId,
    metadata: {
      senderName: senderName,
    },
    key: "mpfj2",
    source: 1,
  };
  if (newMessage.replyKey) {
    message.metadata["AL_REPLY"] = newMessage.replyKey;
  }
  window.Applozic.ALApiService.sendMessage({
    data: {
      message: message,
    },
    success: function(response) {
      Mixpanel.track("One To One Message Send Clicked", {
        "Receiver Name": selectedChatUserName,
        "Receiver UserId": selectedChatUserId,
        SENDER: "web",
        "Agent Name": senderName,
        Message: newText,
      });
      window.Applozic.ALApiService.getMessages({
        data: {
          startIndex: 0,
          userId: selectedChatUserId,
          pageSize: 50,
        },
        success: function(response) {
          dispatch({
            type: NEW_MESSAGE_SENT,
            payload: newText,
          });
          dispatch(updateMessageList(response));
        },
        error: function() {},
      });
    },
    error: function() {},
  });
};

export const sendFileUpload = (newMessage) => (dispatch, getState) => {
  let senderName = GetCurrentStorage("senderName");
  let { messages } = getState();
  let selectedChatUserName =
    messages.selectedChatUserDetails &&
    messages.selectedChatUserDetails[0].userName;
  let selectedChatUserId =
    messages.selectedChatUserDetails &&
    messages.selectedChatUserDetails[0].userId;
  var message = {
    type: 5,
    message: newMessage.caption.trim(),
    to: selectedChatUserId,
    metadata: {
      senderName: senderName,
    },
    source: 1,
  };
  window.Applozic.ALApiService.sendAttachment({
    data: {
      file: newMessage.mediaFile,
      messagePxy: message,
    },
    success: function(response) {
      Mixpanel.track("One To One Message Send Clicked", {
        "Receiver Name": selectedChatUserName,
        "Receiver UserId": selectedChatUserId,
        SENDER: "web",
        "Agent Name": senderName,
        Message: newMessage.caption.trim(),
      });
      window.Applozic.ALApiService.getMessages({
        data: {
          startIndex: 0,
          userId: selectedChatUserId,
          pageSize: 50,
        },
        success: function(response) {
          dispatch({
            type: NEW_MESSAGE_SENT,
            payload: newMessage.caption.trim(),
          });
          dispatch(updateMessageList(response));
        },
        error: function() {},
      });
    },
    error: function() {},
  });
};

export const fetchJobCategories = () => (dispatch, getState) => {
  Get(APIConfig.JOB_CATEGORY_TITLE)
    .then((response) => {
      AddDataToLocalStorage("candidateJobsList", response);
      return dispatch({
        type: UPDATE_JOBS_TITLES_LIST,
        payload: response,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const fetchJobsForTitle = (id, city) => (dispatch) => {
  dispatch({
    type: FETCH_JOBS_LOADING,
    payload: true,
  });
  if (city) {
    let query = `select id from location_city where name like '${city.trim()}%'`;
    Post(APIConfig.FOR_SQL_QUERY, { query })
      .then((res) => {
        let cityId = res && res[0] && res[0].id;
        if (cityId) {
          let jobCategoryQuery = jobCategory(id, cityId);
          Post(APIConfig.FOR_SQL_QUERY, { query: jobCategoryQuery })
            .then((res) => {
              dispatch({
                type: FETCH_JOBS_FOR_TITLE,
                payload: res,
              });
            })
            .catch((error) => {
              console.error(error);
              dispatch({
                type: FETCH_JOBS_LOADING,
                payload: false,
              });
            });
        } else {
          dispatch({
            type: FETCH_JOBS_LOADING,
            payload: false,
          });
        }
      })
      .catch((error) => console.error(error));
  }
};

export const setFAQsOpen = () => (dispatch) => {
  dispatch({
    type: OPEN_FAQ_PANEL,
    payload: true,
  });
};
export const setFAQsClose = () => (dispatch) => {
  dispatch({
    type: CLOSE_FAQ_PANEL,
    payload: true,
  });
};

export const fetchFAQs = () => (dispatch) => {
  Tabletop.init({
    key:
      "https://docs.google.com/spreadsheets/d/1movv1MUn8dmWWjw9T9eXlC2oQqP5SEsr7Hu4V_LywRc/edit?usp=sharing",

    simpleSheet: false,
  }).then(function(data, tabletop) {
    let particularPersonData, sheetName;
    const botId =
      GetCurrentStorage("OneToOnePostingUser") &&
      GetCurrentStorage("OneToOnePostingUser").userId;

    if (botId === "484077" || botId === "471003" || botId === "160290") {
      if (botId === "484077") {
        particularPersonData = data["Riya"].elements;
        sheetName = "Riya";
      } else if (botId === "471003") {
        particularPersonData = data["Nikhil"].elements;
        sheetName = "Nikhil";
      } else if (botId === "160290") {
        particularPersonData = data["Isha"].elements;
        sheetName = "Isha";
      }
    } else {
      particularPersonData = data["Nikhil"].elements;
      sheetName = "Nikhil";
    }
    AddDataToLocalStorage("userFAQList", {
      FAQ: particularPersonData,
      sheetName: sheetName,
    });
    return dispatch({
      type: UPDATE_FAQ_LIST,
      payload: { FAQ: particularPersonData, sheetName: sheetName },
    });
  });
};
