export default {
  BASE_PATH: `${process.env.REACT_APP_BASE_URL}/api/community/`,
  GROUP_BASE: "v2/groups/",
  USER_PROFILE: `${process.env.REACT_APP_BASE_URL}/api/userprofile/v1/user/`,
  GET_OTP: `${process.env.REACT_APP_BASE_URL}/api/userprofile/v1/otp/`,
  LOGIN: `${process.env.REACT_APP_BASE_URL}/apna-auth/otp/login/`,
  BASE_INFO: `${process.env.REACT_APP_BASE_URL}/api/v2/network-info-horizontal/`,
  MCK_FILE_URL: "https://applozic.appspot.com",
  FILE_PREVIEW_URL: "/rest/ws/aws/file/",
  FOR_SQL_QUERY: `${process.env.REACT_APP_BASE_URL}/api/v1/sql-query/`,
  MIX_PANEL_KEY: "b96e1914cc08adb5d3332330e947ca74",
  JOB_CATEGORY_TITLE: `${process.env.REACT_APP_BASE_URL}/api/v1/category/titles/`,
  ENABLER_FEEDBACK: `${process.env.REACT_APP_BASE_URL}/api/retarget/v1/send-enabler-feedback`,
  SELECTED_USER_INFO: `${process.env.REACT_APP_BASE_URL}/api/retarget/v1/dashboard-user-detail`,
};
